import React, { useState, useEffect } from "react"
import ReactDOM from "react-dom"
import Dropdown from "react-bootstrap/Dropdown"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"
import Media from "react-media"
import Select from "react-select"
import { HashRouter as Router, Route, Link } from "react-router-dom"
import { jsonFetch } from "@/src/modules/fetch_helper"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Avatar from "@/components/Messenger/Avatar"
import { syntheticEvent } from "@/util/syntheticEvent"

function Input(props) {
  const [
    selectingMessageTemplateState,
    setSelectingMessageTemplateState,
  ] = useState(false)
  let textareaRef = React.createRef()
  useEffect(() => {
    const interval = setInterval(() => {
      if (textareaRef.current) {
        props.onChange({ target: textareaRef.current })
      }
    }, 100)
    return () => {
      clearInterval(interval)
    }
  }, [props, textareaRef])
  if (props.value.length > 0 && selectingMessageTemplateState == "loading") {
    setSelectingMessageTemplateState("none")
  }
  const selectATemplateLinkText = "select a message template"
  const selectATemplateLink = (
    <a
      href="#"
      onClick={e => {
        e.preventDefault()
        setSelectingMessageTemplateState("select")
      }}
    >
      {selectATemplateLinkText}
    </a>
  )
  const messageTemplateSelect = (
    <Select
      menuPlacement={"top"}
      isSearchable={true}
      options={props.messageTemplates}
      onChange={(value, action) => {
        setSelectingMessageTemplateState("loading")
        props.applyMessageTemplate(value.value)
      }}
      ref={ref => {
        if (ref) {
          ref.setState({ menuIsOpen: true })
        }
      }}
    />
  )
  let button
  let prompt
  let microphone = (
    <a
      data-action="textarea-speech-recognition#toggleRecognition"
      data-target="textarea-speech-recognition.toggle"
      className="conversation-input__microphone"
      hidden={props.readOnly}
    >
      <FontAwesomeIcon icon="microphone" />
    </a>
  )
  if (selectingMessageTemplateState == "loading") {
    prompt = <div className="conversation-input__placeholder">Loading...</div>
  } else if (props.value.length == 0) {
    prompt = (
      <div
        className="conversation-input__placeholder"
        onClick={event => {
          if (
            event.target.classList.contains("conversation-input__placeholder")
          ) {
            textareaRef.current.focus()
          }
        }}
      >
        Type a message
        {!props.readOnly && props.messageTemplates.length > 0 && (
          <React.Fragment>
            {" "}
            or{" "}
            <span className={"conversation-input__message-template-select"}>
              {selectingMessageTemplateState == "select"
                ? messageTemplateSelect
                : selectATemplateLink}
            </span>
          </React.Fragment>
        )}
      </div>
    )
  } else {
    button = (
      <button
        data-action="prompt-to-save#removeListeners"
        onClick={props.onSend}
        disabled={props.readOnly}
        type="submit"
        className={`conversation-input__send ${
          props.readOnly ? "__locked" : ""
        }`}
      >
        <FontAwesomeIcon icon="paper-plane" />
      </button>
    )
  }
  const confirmationMessage =
    "You are about to close this window that still has an unsent message. Would you like to leave anyway?"
  return (
    <div
      className="conversation-input"
      data-controller="prompt-to-save textarea-speech-recognition"
      data-prompt-to-save-confirmation-message={confirmationMessage}
    >
      {prompt}
      <textarea
        className={`conversation-input__input test-conversation-input ${
          props.readOnly ? "__locked" : ""
        }`}
        ref={textareaRef}
        disabled={props.readOnly}
        onChange={props.onChange}
        value={props.value}
        data-target="textarea-speech-recognition.textarea"
      />
      <span className="conversation-input__buttons">
        {microphone}
        {button}
      </span>
    </div>
  )
}

const Message = React.memo(function Message(props) {
  let className = `conversation-message conversation-message--side-${
    props.position
  }`
  if (props.activeMessage) {
    className = `${className} conversation-message--active`
  }
  let media = props.mediaUrls.map((url, i) => (
    <a href={url} target="_blank">
      <img key={i} src={url} />
    </a>
  ))
  let deleteLink, deleteLinkForRightMessage, deleteLinkForLeftMessage
  let bodyClassNames = "conversation-message__body"
  if (props.deleted) {
    bodyClassNames += " conversation-message__body--deleted"
  } else if (!props.readOnly) {
    deleteLink = (
      <a
        className="conversation-message__delete"
        data-confirm="This action cannot be undone. The message content will be permanently deleted after 30 days."
        data-method="delete"
        href={props.messageUrl}
      >
        <i className="align-middle fa-fw fas fa-trash" />
      </a>
    )
  }
  if (props.position === "left") {
    deleteLinkForLeftMessage = deleteLink
  } else {
    deleteLinkForRightMessage = deleteLink
  }

  return (
    <div className={className} data-message-id={props.id}>
      <div className="conversation-message__avatar">
        <Avatar {...props.avatar} />
      </div>
      <div className="conversation-message__content" tabIndex="0">
        {deleteLinkForRightMessage}
        <div className={bodyClassNames}>
          {media}
          {props.text}
        </div>
        {deleteLinkForLeftMessage}
        <div
          className={`conversation-message__time conversation-message__time--${
            props.status
          }`}
        >
          {props.displayStatus}
        </div>
      </div>
    </div>
  )
})

const NEW_SUBSCRIBER_STATE = {
  currentUserSubscription: { receive_notifications: true },
}

export default class Conversation extends React.Component {
  state = { currentUserSubscription: {}, input: "", firstRender: true }

  constructor(props) {
    super(props)
    this.messagesDiv = React.createRef()
  }

  componentDidMount() {
    this._handleConversationChange()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.conversation.id != this.props.conversation.id) {
      this._handleConversationChange()
    }
    if (
      this.previousAndCurrentPropsHaveDifferentMessages(prevProps) ||
      this.state.firstRender
    ) {
      this.setState({ firstRender: false })
      setTimeout(this.scrollToInitialPosition.bind(this))
    }
  }

  previousAndCurrentPropsHaveDifferentMessages(prevProps) {
    return (
      JSON.stringify(prevProps.conversation.messages) !=
      JSON.stringify(this.props.conversation.messages)
    )
  }

  conversationId() {
    return this.props.conversation.id
  }

  currentMessageId() {
    return this.props.messageId || this.props.match.params.messageId
  }

  send = event => {
    const body = this.state.input
    if (body && body.length) {
      this.props.sendMessage(this.conversationId(), body)
      this.setState({ input: "" })
      if (!this.isSubscribed()) {
        this.setState(NEW_SUBSCRIBER_STATE)
      }
      this.scrollToBottom()
    }
  }

  updateInput = event => {
    this.setState({ input: event.target.value })
  }

  scrollToInitialPosition() {
    if (this.currentMessageId()) {
      this.scrollToCurrentMessage()
    } else {
      this.scrollToBottom()
    }
  }

  scrollToBottom() {
    if (this.messagesDiv.current) {
      const el = this.messagesDiv.current
      el.scrollTop = el.scrollHeight
    }
  }

  scrollToCurrentMessage() {
    const messageId = this.currentMessageId()
    const messageEl = $(`[data-message-id=${messageId}]`)[0]
    if (messageEl) {
      const el = this.messagesDiv.current
      el.scrollTop = messageEl.offsetTop - el.offsetTop
    } else {
      this.scrollToBottom()
    }
  }

  shouldComponentUpdate(nextProps, _nextState) {
    if (
      this.props.conversation.messages.length !=
      nextProps.conversation.messages.length
    ) {
      this.scrollToBottom()
    }

    return true
  }

  applyMessageTemplate = messageTemplateId => {
    syntheticEvent(
      document.querySelector("textarea.conversation-input__input"),
      "change"
    )
    this.props
      .renderMessageTemplate(
        this.props.url,
        this.conversationId(),
        messageTemplateId
      )
      .then(body => this.setState({ input: body.trim() }))
  }

  currentUserSubscription = () => {
    return (
      this.props.conversation.subscriptions.find(
        subscription => subscription.user_id == this.props.currentUser.id
      ) || {}
    )
  }

  navigateToConversationsList = () => {
    this.props.history.push(`/`)
  }

  isSubscribed() {
    return Object.keys(this.state.currentUserSubscription).length > 0
  }

  isReceiveNotifications() {
    return this.state.currentUserSubscription.receive_notifications
  }

  toggleSubscription = event => {
    event.preventDefault()
    if (this.isSubscribed()) {
      this.props.unsubscribeFromConversation(this.conversationId())
      this.setState({
        currentUserSubscription: {},
      })
    } else {
      this.props.subscribeToConversation(this.conversationId())
      this.setState(NEW_SUBSCRIBER_STATE)
    }
  }

  toggleReceiveNotifications = event => {
    event.preventDefault()
    if (!this.props.readOnly) {
      const receiveNotifications = !this.isReceiveNotifications()
      this.props.updateReceiveNotificationsForConversation(
        this.conversationId(),
        receiveNotifications
      )
      this.setState({
        currentUserSubscription: {
          receive_notifications: receiveNotifications,
        },
      })
    }
  }

  renderNotificationsTooltip = props => {
    let message = ""
    if (this.isReceiveNotifications()) {
      message =
        "You will receive push notifications when new messages are received"
    } else {
      message =
        "Click to receive push notification when new messages are received"
    }
    return <Tooltip {...props}>{message}</Tooltip>
  }

  renderSubscriptionTooltip = props => {
    let message = ""
    if (this.isSubscribed()) {
      message = "This conversation is in your list"
    } else {
      message = "Click to add this conversation to your list"
    }
    return <Tooltip {...props}>{message}</Tooltip>
  }

  deleteConversation(event) {
    event.preventDefault()
    this.props.deleteConversation(this.conversationId(), this.props.history)
  }

  render() {
    var messages = this.props.conversation.messages.map((m, i) => (
      <Message
        key={i}
        {...m}
        activeMessage={m.id == this.props.messageId}
        readOnly={this.props.readOnly}
      />
    ))
    return (
      <div className="conversation">
        {this.renderTitleBar()}
        <div className="conversation__messages" ref={this.messagesDiv}>
          {messages}
        </div>
        <div className="conversation__input">
          <Input
            applyMessageTemplate={this.applyMessageTemplate}
            messageTemplates={this.props.messageTemplates}
            readOnly={this.props.readOnly}
            value={this.state.input}
            onChange={this.updateInput}
            onSend={this.send}
          />
        </div>
      </div>
    )
  }

  renderTitleBar = () => {
    return (
      <div className="conversation__title">
        {this.renderBackButton()}
        {this.renderConversationName()}
        {this.renderSubscribeButton()}
        {this.renderNotificationButton()}
        <div className="conversation__title-right">
          {this.renderRelatedConversationsDropdown()}
          {this.renderDeleteStatus()}
        </div>
      </div>
    )
  }

  renderBackButton = () => {
    return (
      <Media
        query={window.xlBreakpoint}
        render={() => (
          <span
            onClick={this.navigateToConversationsList}
            className="conversation__back"
          >
            <FontAwesomeIcon icon="angle-left" />
          </span>
        )}
      />
    )
  }

  renderConversationName = () => {
    const person = this.props.conversation.person
    let nickname = ""
    if (person.nickname) {
      nickname = `"${person.nickname}" `
    }
    return `${person.first_name} ${nickname} ${person.last_name}`
  }

  renderSubscribeButton = () => {
    return (
      <OverlayTrigger overlay={this.renderSubscriptionTooltip}>
        <a
          className={`btn btn-secondary conversation__subscribe conversation__subscribe--${this.isSubscribed()} ${
            this.props.readOnly ? "__locked" : ""
          }`}
          href="javascript:void(0)"
          onClick={this.props.readOnly ? undefined : this.toggleSubscription}
        >
          {this.isSubscribed() ? "Subscribed" : "Subscribe"}
        </a>
      </OverlayTrigger>
    )
  }

  renderNotificationButton = () => {
    if (this.isSubscribed()) {
      return (
        <OverlayTrigger overlay={this.renderNotificationsTooltip}>
          <a
            className={`btn btn-secondary conversation__receive-notifications conversation__receive-notifications--${this.isReceiveNotifications()} ${
              this.props.readOnly ? "__locked" : ""
            }`}
            href="#"
            onClick={this.toggleReceiveNotifications}
          >
            <FontAwesomeIcon className="fa-w-16" icon="bell" />
          </a>
        </OverlayTrigger>
      )
    }
  }

  renderRelatedConversationsDropdown = () => {
    if (this.props.conversation.relatedConversations.length && Object.groupBy) {
      const groupedConversations = Object.entries(
        Object.groupBy(
          this.props.conversation.relatedConversations,
          conversation => conversation.communityName
        )
      )
      return (
        <Dropdown>
          <Dropdown.Toggle
            variant="secondary"
            className="conversation__view-related-conversations"
          >
            <FontAwesomeIcon icon="messages" />
            View Related Conversations
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {groupedConversations.map(entry => {
              const communityName = entry[0]
              const conversations = entry[1]
              return (
                <React.Fragment>
                  <Dropdown.Header>{communityName}</Dropdown.Header>
                  {conversations.map(conversation => {
                    return (
                      <Dropdown.Item
                        data-modal={true}
                        href={`${this.props.url}?conversation_id=${
                          conversation.id
                        }&related_conversation_id=${
                          this.props.conversation.id
                        }&modal=true`}
                      >
                        {conversation.title}
                      </Dropdown.Item>
                    )
                  })}
                </React.Fragment>
              )
            })}
          </Dropdown.Menu>
        </Dropdown>
      )
    }
  }

  renderDeleteStatus = () => {
    if (this.props.conversation.discarded_at) {
      return <div className="danger">DELETED</div>
    } else if (
      this.props.currentUser.welcome_home_employee &&
      !this.props.readOnly
    ) {
      return (
        <a
          className="conversation__delete link--welcome-home-employee"
          href="#"
          onClick={e => this.deleteConversation(e)}
        >
          <FontAwesomeIcon icon="times" />
        </a>
      )
    }
  }

  _handleConversationChange() {
    this.setState({
      currentUserSubscription: this.currentUserSubscription(),
    })
    this.props.markAsRead(this.conversationId())
    this.scrollToInitialPosition()
    this.props.loadConversation(this.conversationId())
  }
}
